import React from "react";
import { Box, Link } from "rebass/styled-components";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Main from "../components/main";
import Text from "../components/text";
import { H1 } from "../components/headers";

const TermsLink = styled(Link)`
  display: block;
  text-decoration: underline;
`;

const Help = () => (
  <>
    <SEO title="Data &amp; privacy" />
    <Layout>
      <Main p={[3, 5, 5]}>
        <Box p={3} width={[1, 1, 1 / 2]}>
          <H1 mb={3}>data &amp; privacy</H1>
          <Text color="white" mb={2}>
            Find out how Avicenna collects, uses and manages your data.
          </Text>
          <TermsLink
            variant="bigLink"
            href="https://www.managemymeds.co.uk/PrivacyPolicy.pdf"
            color="white"
            my={3}
          >
            privacy policy
          </TermsLink>
          <TermsLink
            variant="bigLink"
            href="https://www.managemymeds.co.uk/TermsConditions.pdf"
            color="white"
            my={3}
          >
            terms and conditions
          </TermsLink>
          <TermsLink
            variant="bigLink"
            href="https://www.managemymeds.co.uk/cookie-policy"
            color="white"
            my={3}
          >
            cookie policy
          </TermsLink>
        </Box>
      </Main>
    </Layout>
  </>
);

export default Help;
